import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ServerService {

    constructor() {
    }

    get webLink() {
        return 'http://college.loc/';
        // return 'http://localhost:8000/';
    }

}
