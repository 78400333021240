import {environment} from '../../environments/environment';


// Agent
export const URL_agent =
    {
        list_all: environment.API_HOME + 'agent',
        list_active: environment.API_HOME + 'agent/active',
        save: environment.API_HOME + 'agent/store',
        delete: environment.API_HOME + 'agent/delete',
    };


export const URL_auth_login = environment.API_HOME + 'auth/login';
export const URL_password_change = environment.API_HOME + 'password_change';
export const URL_auth_logout = environment.API_HOME + 'auth/logout';
export const URL_users_list = environment.API_HOME + 'users';

export const URL_account_head_group_list = environment.API_HOME + 'accounts/head_group';
export const URL_account_head_list_all = environment.API_HOME + 'accounts/head';
export const URL_account_head_view = environment.API_HOME + 'accounts/head/view/';
export const URL_account_head_list_tree_data = environment.API_HOME + 'accounts/head/list_tree_data';
export const URL_account_head_model_names = environment.API_HOME + 'accounts/head/model_names';
export const URL_account_head_tree = environment.API_HOME + 'accounts/head/tree';
export const URL_account_head_store = environment.API_HOME + 'accounts/head/store';
export const URL_account_head_update = environment.API_HOME + 'accounts/head/update';
export const URL_account_head_search = environment.API_HOME + 'accounts/head/search';
export const URL_account_head_search_blank = environment.API_HOME + 'accounts/head/search_blank';
export const URL_account_head_reference = environment.API_HOME + 'accounts/head/reference';
export const URL_account_head_delete = environment.API_HOME + 'accounts/head/delete';

export const URL_college_list_all = environment.API_HOME + 'college';
export const URL_college_list_active = environment.API_HOME + 'college/list/active';
export const URL_college_save = environment.API_HOME + 'college/store';
export const URL_college_delete = environment.API_HOME + 'college/delete';

export const URL_candidate_store = environment.API_HOME + 'candidate/store';
export const URL_candidate_details = environment.API_HOME + 'candidate/details';
export const URL_candidate_delete = environment.API_HOME + 'candidate/delete';
export const URL_candidate_list = environment.API_HOME + 'candidate';
export const URL_candidate_search = environment.API_HOME + 'candidate/search';
export const URL_candidate_search_print = environment.API_HOME + 'candidate/search/print';

// Payment => receipt
export const URL_payment_receipt_store = environment.API_HOME + 'payment/receipt/store';
export const URL_payment_receipt_recent = environment.API_HOME + 'payment/receipt';
export const URL_payment_receipt_search = environment.API_HOME + 'payment/receipt/search';
export const URL_payment_receipt_details = environment.API_HOME + 'payment/receipt/details';
export const URL_payment_receipt_cancel = environment.API_HOME + 'payment/receipt/cancel';
export const URL_payment_receipt_print = environment.API_HOME + 'payment/receipt/print';

// Payment => VOUCHER
export const URL_payment_voucher_store = environment.API_HOME + 'payment/voucher/store';
export const URL_payment_voucher_recent = environment.API_HOME + 'payment/voucher';
export const URL_payment_voucher_search = environment.API_HOME + 'payment/voucher/search';
export const URL_payment_voucher_details = environment.API_HOME + 'payment/voucher/details';
export const URL_payment_voucher_cancel = environment.API_HOME + 'payment/voucher/cancel';
export const URL_payment_voucher_print = environment.API_HOME + 'payment/voucher/print';

// Payment => Reports
export const URL_payment_day_book_search = environment.API_HOME + 'payment/report/day_book';
export const URL_payment_day_book_print = environment.API_HOME + 'payment/report/day_book/print';
export const URL_payment_ledger_search = environment.API_HOME + 'payment/report/ledger';
export const URL_payment_ledger_print = environment.API_HOME + 'payment/report/ledger/print';
export const URL_balance_sheet = environment.API_HOME + 'payment/report/balance_sheet';
export const URL_profit_and_loss = environment.API_HOME + 'payment/report/profit_loss_account';
export const URL_profit_and_loss_print = environment.API_HOME + 'payment/report/profit_loss_account_print';


// Wallet
export const URL_wallet_list = environment.API_HOME + 'wallet';


// Staff
export const URL_staff_store = environment.API_HOME + 'staff/store';
export const URL_staff_update = environment.API_HOME + 'staff/update';
export const URL_staff_details = environment.API_HOME + 'staff/details';
export const URL_staff_delete = environment.API_HOME + 'staff/delete';
export const URL_staff_resign = environment.API_HOME + 'staff/resign';
export const URL_staff_vacate = environment.API_HOME + 'staff/vacate';
export const URL_staff_list = environment.API_HOME + 'staff';
export const URL_staff_search = environment.API_HOME + 'staff/search';
export const URL_staff_salary_balance = environment.API_HOME + 'staff/salary_balance';


// Contacts

export const URL_contact =
    {
        list: environment.API_HOME + 'contact',
        save: environment.API_HOME + 'contact/store',
        details: environment.API_HOME + 'contact/details',
        search: environment.API_HOME + 'contact/search',
        remove: environment.API_HOME + 'contact/delete',
    };
// Staff Leave
export const URL_staff_leave =
    {
        pre_data: environment.API_HOME + 'staff_leave/pre_data',
        save: environment.API_HOME + 'staff_leave/store',
        search: environment.API_HOME + 'staff_leave/search',
        remove: environment.API_HOME + 'staff_leave/delete',
    };

// Reminders

export const URL_reminder =
    {
        save: environment.API_HOME + 'reminder/store',
        search: environment.API_HOME + 'reminder/search',
        recent: environment.API_HOME + 'reminder/recent',
        recent_by_month: environment.API_HOME + 'reminder/recent_by_month',
        remove: environment.API_HOME + 'reminder/delete'
    };

// Financial Year
export const URL_financial_year =
    {
        save: environment.API_HOME + 'financial_year/store',
        search: environment.API_HOME + 'financial_year/search',
        remove: environment.API_HOME + 'financial_year/delete',
        set_as_current: environment.API_HOME + 'financial_year/set_as_current'
    };

// Personal Loan
export const URL_personal_loan =
    {
        grand: environment.API_HOME + 'personal_loan/grand',
        pay_off: environment.API_HOME + 'personal_loan/pay_off',
        due_list: environment.API_HOME + 'personal_loan/due_list',
        details: environment.API_HOME + 'personal_loan/details',
    };
