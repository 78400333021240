import {Injectable} from '@angular/core';

export interface Menu {
    name: string;
    type: string;
    icon: string;
    state?: string;
    children?: any;
}

const MENUITEMS = [
    {
        name: 'Dashboard',
        type: 'link',
        icon: 'supervisor_account',
        state: 'dashboard'
    },
    {
        name: 'Candidate',
        type: 'title',
        icon: 'supervisor_account',
        children: [
            {state: 'candidate', name: 'Add New', type: 'link', icon: 'person_add'},
            {state: 'candidate-list', name: 'Search', type: 'link', icon: 'youtube_searched_for'},

        ]
    },
    {
        name: 'Receipts',
        type: 'title',
        icon: 'receipt',
        children: [
            {state: 'receipt', name: 'Add New', type: 'link', icon: 'playlist_add'},
            {state: 'receipt-list', name: 'Search', type: 'link', icon: 'youtube_searched_for'},

        ]
    },
    {
        name: 'Vouchers',
        type: 'title',
        icon: 'assignment',
        children: [
            {state: 'voucher', name: 'Add New', type: 'link', icon: 'playlist_add'},
            {state: 'voucher-list', name: 'Search', type: 'link', icon: 'youtube_searched_for'},

        ]
    },
    {
        name: 'Reports',
        type: 'title',
        icon: 'menu',
        children: [
            {state: 'day-book', name: 'Day Book', type: 'link', icon: 'description'},
            {state: 'ledger', name: 'Ledger', type: 'link', icon: 'account_balance_wallet'},
            {state: 'balance_sheet', name: 'Balance Sheet', type: 'link', icon: 'insert_chart'},
            {state: 'profit_and_loss', name: 'Profit & Loss', type: 'link', icon: 'confirmation_number'},

        ]
    },
    {
        name: 'Settings',
        type: 'title',
        icon: 'settings',
        children: [
            {state: 'college', name: 'Collage', type: 'link', icon: 'view_comfy'},
            {state: 'agent', name: 'Agent', type: 'link', icon: 'contacts'},
            {state: 'accounts-head-list', name: 'Accounts Head', type: 'link', icon: 'settings_input_composite'},
            {state: 'financial-year', name: 'Financial Year', type: 'link', icon: 'filter_frames'},
            {state: 'password_change', name: 'Password change', type: 'link', icon: 'security'},
            {state: 'logout', name: 'Log Out', type: 'link', icon: 'settings_power'},
        ]
    },


];

@Injectable()

export class MenuItems {
    getMenuitem(): Menu[] {
        return MENUITEMS;
    }

}
